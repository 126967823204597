import React from 'react'
import loadable from '@loadable/component';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const Select = loadable(() => import('react-select'))

const getCustomStyles = userDarkMode => {
  return {
    option: (provided, state) => ({
      ...provided,
      color: userDarkMode ? '#FAFAFA' : 'rgba(0, 0, 0, 0.56)',
      textAling: 'Left',
      backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
      fontSize: 16,
      paddingRight: 20,
      paddingLeft: 20,
      textAlign: 'Left',
      zIndex: 999,
      '&:hover': {
        backgroundColor: userDarkMode
          ? 'rgb(43, 47, 54)'
          : 'rgba(0, 0, 0, 0.04)',
      },
    }),
    control: (provided, state) => {
      return {
        ...provided,
        backgroundColor: userDarkMode
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)',
        border: `0px solid transparent !important`,
        marginBottom: 2,
        marginTop: 2,
        paddingRight: 10,
        paddingLeft: 10,
        zIndex: 999,
        fontSize: 16,
        width: '130px',
        outline: 'none',
        height: 44,
        minHeight: 20,
        borderRadius: 22,
        boxShadow: 'none',
        color: userDarkMode
          ? 'rgba(255, 255, 255, 0.56)'
          : 'rgba(0, 0, 0, 0.56)',
        '&:hover': {
          backgroundColor: userDarkMode
            ? 'rgb(43, 47, 54)'
            : 'rgba(0, 0, 0, 0.04)',
        },
      }
    },
    menu: provided => ({
      ...provided,
      borderRadius: 12,
      zIndex: 999,
      maxWidth: 200
    }),
    menuList: provided => ({
      ...provided,
      backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
      borderRadius: 10,
    }),
    singleValue: (provided, state) => {
      const color = 'inherit'
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, color, opacity, transition }
    },
  }
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const MiniChartRangeSelector = (props) => {
  const { miniChartRange, setMiniChartRange, loading } = props;
  const { t, ready } = useTranslation(['tables'], { useSuspense: false })
  
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
      ? userSettings.dark_mode
      : false

  const rangeOptions = [
    { label: `${t(`OneDay`)}`, value: 'OneDay' },
    { label: `${t(`SevenDay`)}`, value: 'SevenDay' },
    { label: `${t(`ThirtyDay`)}`, value: 'ThirtyDay' },
    { label: `${t(`OneYear`)}`, value: 'OneYear' },
    { label: `${t(`all`)}`, value: 'all' },
  ]

  const classes = useStyles();
  return ready && (
    <Tooltip title="">
      <div className={classes.wrapper} >
        {loading
          ? <Spinner
            variant={userDarkMode ? 'light' : 'dark'}
            animation="border"
            size="sm"
          />
          : <Select
            className="mr-2"
            isSearchable={false}
            options={rangeOptions}
            styles={getCustomStyles(userDarkMode)}
            borderRadius={0}
            defaultValue={rangeOptions[1]}
            onChange={option => setMiniChartRange(option.value)}
            components={{ IndicatorSeparator: () => null }}
            value={rangeOptions.find(el => el.value === miniChartRange)}
          />
        }
      </div>
    </Tooltip>
  )
}
export default MiniChartRangeSelector